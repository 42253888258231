$primary: #184d47; //007b65
$primary-dark: #005762;
$primary-2: #007462;
$primary-3: #007364;
$primary-4: #006865;
$primary-light: rgba(0, 123, 101, 0.1);
$primary-light2: rgba(0, 123, 101, 0.5);

$secondary-rd: #eebb4d;
$secondary-rd-hover: #f8c65a;
$off-white-rd: rgba(9, 30, 66, 0.04);

$link-color: #05687c;

$secondary-dark: #0b1d2b;
$secondary-2: #2b2d42;
$secondary-3: #484b69;
$secondary-4: #8d99ae;
$secondary-off-white: #edf2f4;
$secondary-off-white-2: #e3e8ea;
$secondary-demi-white: #fbfcfd;
$secondary-demi-white-2: #f3f6f9;
$tint: #f5f6f7;
$tint2: #c0cbd0;
$tint3: #e5e9ee;
$white: #fff;
$black: #000;
$grey: grey;
$red: #ff6250;
$facebook: #1976d3;
$viber: #7d3daf;
$border-color: #b7cfff;

$primary-gradient: linear-gradient(to right, #005762, #007b65);
$primary-gradient-reverse: linear-gradient(to right, #007b65, #005762);
$secondary-gradient: linear-gradient(to right, #0b132b, #2b2d42);

$box-shadow-button: rgba(0, 116, 98, 0.3) 5px 5px 10px;
$box-shadow-button2: 0px 10px 4px -5px rgba(0, 116, 98, 0.4);
$box-shadow-button-secondary: 0px 10px 4px -5px rgba(0, 29, 25, 0.2);

//redesign
$primary-light-r: #c4d2cc;
$light-grey: #616161;
$blue-border: #a4c2ff;
$danger: #de350b;
$warning: #f3b52f;
$warning2: #ff8a00;
$success: #00875a;
$link-color: #0091ae;
$border-color: #dfe1e6;

$default-color: #f4f5f7;
$description-color: #6b778c;
$text-color: #172b4d;
