@import 'colors';
.pagination {
  ul {
    display: flex;
    align-items: center;

    li {
      margin: 10px;
      font-size: 18px;
      cursor: pointer;
      list-style: none;

      .prev-next {
        background: $secondary-off-white;
        border-radius: 5px;
      }
      button {
        padding: 8px 12px;
        color: $secondary-3;
        display: inline-block;
        border: none;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
          background: $secondary-off-white;
          border-radius: 5px;
        }
        &:focus {
          outline: none;
        }
      }

      &.is-active {
        button {
          color: $white;
          background: $primary-gradient;
          border-radius: 5px;
          cursor: pointer;
          border: none;
          &:hover {
            background: $primary-gradient-reverse;
            color: $white !important;
            box-shadow: $box-shadow-button2;
          }
        }
      }
    }
  }
}
