@import 'colors';

body {
  background-color: $secondary-demi-white-2;
}

pre,
code {
  font-family: monospace !important;
}

.attribute-container__header {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 5px;
  padding: 10px;
}

body.windows::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
  background-color: $secondary-demi-white-2;
}

body.windows::-webkit-scrollbar-thumb {
  height: 8px;
  background-color: rgba($primary, 0.1);
}

.layout {
  &__header {
    display: flex;
    margin: 30px 50px;
    align-items: center;

    &-info {
      margin-right: 20px;

      &-title {
        font-size: 1.5rem;
        color: $secondary-dark;
        margin: 0;
        line-height: 1.8rem;
        font-weight: 500;
      }
    }
  }

  &__wrapper {
    display: flex;
  }

  &__sidebar {
    padding: 15px;
    background-color: transparent;
    border: 1px solid transparent;
    width: 300px;
    height: calc(100vh - 35px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.25rem !important;
    }

    &::-webkit-scrollbar-track {
      background: $tint !important;
    }

    &::-webkit-scrollbar-thumb {
      background: $tint2 !important;
    }

    border-radius: 5px;
    margin: 0 10px 0 50px;

    > h5 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-size: 0.9rem;

      img {
        height: auto;
        width: 16px;
        margin-right: 10px;
      }
    }

    &-meta {
      margin-bottom: 15px;

      h5 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 0.8rem;
      }

      p {
        font-size: 0.75rem;
      }
    }
  }

  &__content {
    position: relative;
    padding: 0 20px;
    flex: 1;
    height: calc(100vh - 35px);
    overflow: auto;
  }
}

.layout-container {
  display: flex;
}

.layout-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div:nth-child(1) {
    margin-right: 20px;
  }

  div {
    div {
      img {
        cursor: pointer;
      }
    }
  }
}

.project-change__list {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: $tint;
    -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  &::-webkit-scrollbar-thumb:vertical {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
  }

  &::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61);
    -webkit-border-radius: 100px;
  }
}

.round-caret__buttonstyle {
  background: $white !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: space-between !important;

  &:hover {
    background: $white !important;
  }
}

.profile-button {
  display: flex !important;
  justify-content: space-between !important;
  margin: auto -7px 20px !important;

  &:hover {
    background: none !important;
  }
}

.button_class {
  background: transparent !important;
  height: 0 !important;
  width: 0 !important;

  &:focus {
    box-shadow: none !important;
  }
}

.button-alice__gradient {
  background: $primary-gradient !important;
  color: $white !important;
  box-shadow: none;
  padding: 0 30px !important;
  transition: all 0.5s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    box-shadow: $box-shadow-button2 !important;
  }
}

.button-alice__gradient__grey {
  background: grey !important;
  color: $white !important;
  box-shadow: none;
  padding: 0 30px !important;
  transition: all 0.5s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    box-shadow: $box-shadow-button2 !important;
  }
}

.button-alice__gradient-small-button {
  background: $primary-gradient !important;
  color: $white !important;
  box-shadow: none;
  transition: all 0.5s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    box-shadow: $box-shadow-button2 !important;
  }
}

.button-alice__secondary {
  background: $white !important;
  color: $red !important;
  padding: 0 30px !important;
  box-shadow: none;
  transition: all 0.2s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    background: $white !important;
    box-shadow: 0px 0px 2px $tint2 !important;
  }
}

.button-alice__secondary_2 {
  background: $tint !important;
  color: $red !important;
  padding: 0 30px !important;
  box-shadow: none;
  transition: all 0.2s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    background: $tint !important;
    box-shadow: $box-shadow-button-secondary !important;
  }
}

.button-alice__white {
  background: $white !important;
  color: $secondary-3 !important;
  padding: 0 30px !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in !important;
  border: 1px solid $tint2 !important;

  &-select {
    select {
      background: white !important;
      border-radius: 0;
      box-shadow: inset 0 0 0 1px rgba(67, 111, 91, 0.3),
        inset 0 1px 2px rgba(67, 111, 81, 0.14);
    }
  }

  svg {
    circle {
      stroke: $secondary-3;
    }
  }

  &:hover {
    background: $white !important;
    border: 1px solid $secondary-4;
  }

  &:focus,
  &:active,
  &:visited {
    box-shadow: none !important;
  }
}

.alice_btn-white-primary {
  background: $white !important;
  color: $primary !important;
  padding: 0 30px !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in !important;
  border: 0.5px solid $primary !important;

  svg {
    circle {
      stroke: $secondary-3;
    }
  }

  &:hover,
  &:active {
    background: $primary !important;
    color: $white !important;
  }

  &:visited {
    box-shadow: none !important;
  }
}

.alice-connect-style {
  padding: 0 30px !important;
  transition: all 0.2s ease-in;

  svg {
    circle {
      stroke: $white;
    }
  }
}

@mixin platform-button($color) {
  background: $color !important;
  border: none !important;
  color: $white !important;
  &:hover {
    background: darken($color, 10%) !important;
    border: none !important;
    color: $white !important;
  }
}

.facebook {
  @include platform-button($facebook);
}

.viber {
  @include platform-button($viber);
}

.webchat {
  @include platform-button($primary);
}

.danger {
  @include platform-button($red);
}

.facebook-button-connect {
  background: $facebook !important;
  padding: 13px 30px !important;
  border: none;
  border-radius: 5px;
  color: white !important;
  cursor: pointer !important;
  transition: all 0.3s ease-in;
  transform: scale(0.95);

  i {
    margin-right: 10px;
  }

  &:hover {
    box-shadow: $box-shadow-button-secondary;
    background: darken($facebook, 10%) !important;
    transform: scale(1);
  }
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

.small {
  &__header {
    display: flex;

    &-icon {
      width: 60px;
      height: 60px;
    }

    &-title {
      font-size: 1.8rem;
      color: $secondary-dark;
      margin: 0;
      line-height: 1.8rem;
      font-weight: 500;
    }

    &-subtitle {
      margin-top: 5px;
      font-size: 0.8rem;
      color: $secondary-4;
      font-weight: 300;
    }

    &-info {
      margin-left: 20px;
    }
  }
}

.input-label {
  color: #425a70;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.builder-main__alert {
  a {
    color: $primary;
    text-decoration: none;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 1px solid rgba($primary, 0.2);
    box-shadow: inset 0 -2px 0 0 rgba($primary, 0.2);

    &:hover {
      background: rgba($primary, 0.2);
    }
  }
}

.layout-body {
  &__no-scroll {
    height: calc(100vh - 40px);
    padding: 0 60px 10px;
    font-family: $poppins;
    position: relative;
    overflow: hidden;
    margin-top: 17px;
  }

  &__scroll {
    height: calc(100vh - 110px);
    padding: 0 60px 10px;
    font-family: $poppins;
    position: relative;
    overflow: auto;
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 20px;
  color: #425a70;
  letter-spacing: -0.05px;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.tint-text {
  color: $tint2;
}

.select-menu__default {
  button {
    background: $white;
    width: 100%;
    text-transform: capitalize;
    display: flex;
    justify-content: center;

    &:hover,
    &:focus {
      background: $tint !important;
      background-image: none !important;
    }

    &:disabled {
      cursor: not-allowed;
      box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14);
      color: $secondary-3;
    }
  }
}

.error-text {
  color: darken($red, 20%);
  font-size: 0.8rem;
}

#fc_frame {
  bottom: 15px;
  height: 75px;
  min-height: 73px;
  min-width: 65px;
  overflow: hidden;
  position: fixed;
  right: 15px;
  width: 70px;
  z-index: 2147483600;
}

.white-select-button {
  box-shadow: none !important;
  background: $tint !important;

  &:focus {
    box-shadow: none !important;
  }
}

.alice-btn_border {
  background: transparent !important;
  box-shadow: none !important;
  border: 1px solid $primary;

  &:focus {
    background: transparent !important;
    box-shadow: none !important;
    border: 1px solid $primary-dark;
  }
}

// to hide overflow of fc_frame while it loading...

#fc_frame {
  bottom: 15px;
  height: 75px;
  min-height: 73px;
  min-width: 65px;
  overflow: hidden;
  position: fixed;
  right: 15px;
  width: 70px;
  z-index: 2147483600;
}

.button-offFocus:focus {
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14) !important;
}

.btn {
  &-danger {
    background: $red !important;
    color: $white !important;
    box-shadow: none !important;

    svg {
      path {
        fill: $white !important;
      }
    }

    &:focus,
    &:hover,
    &:visited,
    &:active {
      background: darken($red, 10%) !important;
      color: $white !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &-success {
    background: $primary !important;
    color: $white !important;
    box-shadow: none !important;

    svg {
      path {
        fill: $white !important;
      }
    }

    &:focus,
    &:hover,
    &:visited,
    &:active {
      background: darken($primary, 10%) !important;
      color: $white !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      background: $tint3 !important;
      color: $secondary-4 !important;
    }
  }

  &-offwhite {
    background: $secondary-4 !important;
    color: $white !important;
    box-shadow: none !important;

    svg {
      path {
        fill: $white !important;
      }
    }

    &:focus,
    &:hover,
    &:visited,
    &:active {
      background: darken($secondary-4, 10%) !important;
      color: $white !important;
      outline: none;
      box-shadow: none !important;
    }
  }

  &-dark {
    background: $secondary-2 !important;
    color: $white !important;
    box-shadow: none !important;

    svg {
      path {
        fill: $white !important;
      }
    }

    &:focus,
    &:hover,
    &:visited,
    &:active {
      background: darken($secondary-2, 10%) !important;
      color: $white !important;
      outline: none;
      box-shadow: none !important;
    }
  }
}

.btn-transparent {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
}

.tag-alice {
  margin: 3px 8px;
  background: $primary-light;
  color: $primary;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  transition: all 0.5s ease-in;
  height: fit-content;
  cursor: pointer;
  margin-left: 0;

  button {
    svg {
      path {
        fill: $primary;
      }
    }
  }

  &:hover {
    background: $primary;
    color: $white;

    button {
      svg {
        path {
          fill: $white;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.1) !important;
      }
    }
  }
}

.ticket-block-button {
  justify-content: space-between !important;
  max-width: 250px;
  margin-top: 10px;
}

.btn-outline-danger {
  background: transparent !important;
  border: 1px solid $red;
  color: $red;
  transition: all 0.5s ease;

  &:hover,
  &:focus,
  &:active {
    background: $red !important;
    color: $white;
  }
}

.active-switch-green {
  > input {
    &:checked {
      + {
        div {
          background-color: #26a559;
          color: white;
        }
      }

      &:hover {
        + {
          div {
            background-color: #26a559;
            color: white;
          }
        }
      }
    }

    &:not([disabled]) {
      &:checked {
        &:active {
          + {
            div {
              background-color: #26a559;
              color: white;
            }
          }
        }
      }
    }
  }
}

.fs-12px {
  font-size: 12px !important;
}

.primary-hr {
  border-bottom: 1px solid $blue-border;
  margin-bottom: 30px;
}

.rd-input-style {
  border-radius: 0;
  box-shadow: inset 0 0 0 1px #3378b94d, inset 0 1px 2px #4b7faf24;
}

.rd-input-style-combobox {
  button,
  input {
    box-shadow: inset 0 0 0 1px #3378b94d, inset 0 1px 2px #4b7faf24;
    border-radius: 0;

    &:focus {
      box-shadow: inset 0 0 0 1px #3378b94d, inset 0 1px 2px #4b7faf24;
    }
  }
}

.rd-input-style-checkbox {
  input:checked + div {
    background-image: linear-gradient(
      to bottom,
      $secondary-rd,
      $secondary-rd
    ) !important;
  }
}

.nav-avatar-container {
  position: relative;

  .dashboard-team {
    position: absolute;
    top: 5px;
    right: -26px;
    box-shadow: 0px 0px 0px 1px #fff;
  }
}

.profile-popover__header {
  margin-left: 10px;
  padding: 20px 10px 0;
  display: flex;
  align-items: center;
  color: grey;
}

.primary-tinted-button {
  background: $primary-light;
  color: $primary;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:active {
    background: darken($primary-light, 10%) !important;
  }
}

.btn-green-icon {
  svg {
    fill: $primary-3 !important;
  }
}

.alice-branded-tab {
  //background: red;
  .css-11bc0q4[aria-current='page'],
  [data-css-11bc0q4][aria-current='page'],
  .css-11bc0q4[aria-selected='true'],
  [data-css-11bc0q4][aria-selected='true'],
  .css-11bc0q4:active,
  [data-css-11bc0q4]:active {
    background: $primary-light !important;
    color: $primary;
  }
}

.custom-input-style {
  border-radius: 0 !important;
  border: 2px solid $border-color !important;
  background: $white !important;
  box-shadow: none !important;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background: $white !important;
    box-shadow: inset 0 0 2px rgba(111, 110, 67, 0.14),
      0 0 0 3px rgba(202, 179, 16, 0.14) !important;
  }

  &[aria-invalid='true'] {
    border: 1px solid red !important;
  }
}

.block-title-container {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}

input,
textarea {
  font-family: $poppins;
}

.button-black {
  background: #0b1d2b !important;
  color: $white !important;
  box-shadow: none !important;
  padding: 0 30px !important;
  transition: all 0.5s ease-in;
  text-align: center;
  display: block !important;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    box-shadow: $box-shadow-button-secondary !important;
  }
}

.button-danger {
  background: #fff !important;
  color: $danger !important;
  box-shadow: none;
  border: 1px solid $danger;
  padding: 0 30px !important;
  transition: all 0.5s ease-in;
  text-align: center;
  display: block;

  svg {
    circle {
      stroke: $white;
    }
  }

  &:hover {
    border: 1px solid $danger;
    background: $danger;
    color: $white;
    box-shadow: $box-shadow-button-secondary !important;
  }
}

.alice-icon-btn {
  &__default {
    background: $default-color;
  }
}

.alice-btn {
  &__secondary {
    background: $secondary-rd !important;
    color: $white !important;
    padding: 0 30px;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $white;
      }
    }

    &:hover {
      background: darken($secondary-rd, 5%) !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }

  &__secondary-outlined {
    background: transparent !important;
    color: $secondary-2 !important;
    border: 2px solid $secondary-rd !important;
    padding: 0 20px;
    box-shadow: none !important;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $white;
      }
    }

    &:hover {
      background: $secondary-rd !important;
      color: white !important;
    }
  }

  &__default-outlined {
    background: transparent !important;
    border: 2px solid $default-color !important;
    color: $secondary-2 !important;
    padding: 0 20px !important;
    box-shadow: none !important;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $secondary-2;
      }
    }

    &:hover {
      background: $default-color !important;
      box-shadow: none !important;
    }
  }

  &__default {
    background: $default-color !important;
    color: $secondary-2 !important;
    padding: 0 20px;
    transition: all 0.2s ease-in;
    box-shadow: none !important;

    svg {
      circle {
        stroke: $secondary-2;
      }
    }

    &:hover {
      background: darken($default-color, 5%) !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }

  &__danger {
    background: $danger !important;
    color: $white !important;
    padding: 0 30px !important;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $white;
      }
    }

    &:hover {
      background: $danger !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }
  &__warning {
    background: $warning !important;
    color: $white !important;
    padding: 0 30px !important;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $white;
      }
    }

    &:hover {
      background: $warning2 !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }

  &__warning {
    background: $warning2 !important;
    color: $white !important;
    padding: 0 30px !important;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $white;
      }
    }

    &:hover {
      background: $warning2 !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }

  &__off-white {
    background: $off-white-rd !important;
    color: $secondary-dark !important;
    padding: 0 30px !important;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $secondary-dark;
      }
    }

    &:hover {
      background: $off-white-rd !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }

  &__minimal-black {
    background: transparent !important;
    color: $secondary-dark !important;
    padding: 0 30px !important;
    box-shadow: none;
    transition: all 0.2s ease-in;

    svg {
      circle {
        stroke: $secondary-dark;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none !important;
    }

    &:hover {
      background: transparent !important;
      box-shadow: 0px 0px 2px $tint2 !important;
    }
  }
}

.rd-modal-style {
  border-radius: 2px !important;
  padding: 15px;

  &__title {
    font-size: 20px;
    font-weight: 500;
    font-family: $poppins;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    font-family: $poppins;
    color: #6b778c;
    margin: 10px 10px 0 10px;
  }
}

//tag -redesign
.alice-tag {
  padding: 3px 8px;
  margin: 3px 5px 3px 0;
  border-radius: 3px;
  color: #172b4d;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;

  &-default {
    background: #f4f5f7;
  }

  &-success {
    background: #d6efc7;
  }

  &-danger {
    background: #ffebe6;
  }

  &-active {
    background: #ffe380;
  }
}

.alice-rd-search {
  border: 2px solid #dfe1e6;
  box-shadow: none;

  &:focus {
    box-shadow: inset 0 0 2px rgba(111, 110, 67, 0.14),
      0 0 0 3px rgba(202, 179, 16, 0.14) !important;
  }
}

.collapse-element {
  cursor: pointer;

  &__header {
    background: $default-color;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &-active {
      background: $secondary-rd;
      color: white;
    }

    &-default {
      background: darken($default-color, 3%);
      color: $secondary-dark;
    }

    &-title {
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  &__body {
    margin-top: 10px;
  }
}

.date-picker__redesign {
  position: relative;

  &-container {
    margin-top: 3px;

    input {
      width: 100%;
      font-size: 0.875rem;
      background: $default-color;
      border: 2px solid transparent;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;

      &:focus,
      &:hover,
      &:visited {
        outline: none;
        border: 2px solid $secondary-rd;
      }
    }
  }
}

.filter-label {
  font-size: 0.75rem;
  font-weight: 600;
  color: #6b778c;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $secondary-rd;
  border-radius: 3px;
}

.rdtPicker td.rdtToday:before {
  content: '';
  border-bottom: 7px solid $secondary-rd;
}

.switch-rd {
  input:checked + div {
    background: $secondary-rd !important;
  }

  input:not([disabled]):focus + div {
    box-shadow: 0 0 0 3px #eebb4c5e !important;
  }

  input:checked:hover + div {
    background: darken($secondary-rd, 5%) !important;
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.table-head-rd {
  background: transparent !important;
  border-bottom: 2px solid #e4e7eb !important;
}

.radio-rd {
  input:checked + div,
  input:not([disabled]):checked:hover + div {
    background: linear-gradient(to bottom, #edba4c, #edba4c) !important;
  }
}

.trial-header {
  border-bottom: 1px solid #dfe1e6;
  margin: 30px;
  padding-bottom: 15px;
}

.iconButton-offFocus {
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
.description {
  font-size: 0.75rem;
  color: $description-color;
}

.alice-hr {
  border-top: 1px solid $border-color;
}
