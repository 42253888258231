@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';

.rules-no-data {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  &__title {
    margin-top: 20px;
    font-family: $poppins;
    font-weight: 500;
    font-size: 16px;
    color: #6b778c;
  }
}
.rules-card {
  &__container {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
    background: $white;
  }
  &__elem {
    &-container {
      display: flex;
    }
    &-item {
      &-child {
        margin-right: 10px;
      }
    }
  }
}
.rules-select {
  &__control {
    border-color: transparent !important;
    background: #f4f5f7 !important;
    &--is-disabled {
      background: #f4f5f7 !important;
    }
  }
  &__indicator-separator {
    display: none !important;
  }
}
.rules-field {
  border: 1px solid $border-color !important;
  box-shadow: none !important;
  color: hsl(0, 0%, 20%) !important;
  &:focus,
  &:hover {
    border: 1px solid $secondary-rd !important;
  }
  &:disabled {
    background: #f4f5f7 !important;
    border-color: transparent !important;
    color: hsl(0, 0%, 60%) !important;
  }
}
.primary-border {
  border: 1px solid $secondary-rd !important;
}
