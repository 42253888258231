@import './../../../../assets/styles/typography';
@import './../../../../assets/styles/colors';
.reporting-container {
  margin: 30px 40px 0;
  overflow: auto;
  height: 95vh;
  &::-webkit-scrollbar {
    display: none;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 40px;
    ul {
      display: flex;
      flex: 1.7;
      justify-content: flex-start;
      margin-right: 100px;
      padding-top: 3px;
      margin-top: -10px;
      li {
        list-style: none;
        cursor: pointer;
        font-size: 16px;
        margin-right: 25px;
      }
      .active {
        color: $primary;
        text-decoration: underline;
        font-weight: bold;
      }
    }
    div {
      padding-top: 3px;
    }
  }
  &__user {
    &-row-card {
      background: $white;
      display: flex;
      border-radius: 5px;
      border: 1px solid $secondary-off-white;
      //box-shadow: 0 0 10px 2px #cddae6;
      margin: 30px 40px 20px;
      padding: 30px 15px;
      position: relative;
      &-elem {
        flex: 1;
        display: flex;
        justify-content: center;
        &-title {
          font-family: $poppins;
          font-size: 0.88rem;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 5px;
          letter-spacing: 0em;
          color: $grey;
          display: flex;
          align-items: center;
        }
        &-data {
          font-family: $poppins;
          font-size: 2.5rem;
          font-weight: 500;
          line-height: 2.5rem;
        }
        &-info {
          font-size: 0.75rem;
          color: $secondary-4;
        }
        &:not(:last-child) {
          border-right: 1px solid #e9ebf4;
        }
      }
    }
    &-card-container {
      margin: 30px 40px 20px;
      display: flex;
      &-elem {
        flex: 1;
        background: $white;
        display: flex;
        border-radius: 5px;
        border: 1px solid $secondary-off-white;
        //box-shadow: 0 0 10px 2px #cddae6;
        padding: 20px 15px;
        align-items: flex-start;
        &:not(:last-child) {
          margin-right: 15px;
        }
        &-data {
          flex: 1;
          .report-title {
            color: $grey;
            font-size: 0.88rem;
            align-items: center;
            display: flex;
          }
          .report-data {
            font-family: $poppins;
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 2.5rem;
          }
          .report-additional-data {
            text-transform: uppercase;
            font-size: 1rem;
            color: $secondary-2;
          }
          .report-growth-section {
            font-size: 0.75rem;
            color: $secondary-4;
          }
          .report-time-zone {
            font-size: 1.5rem;
          }
          .warning-tint {
            background: lighten($warning, 30%) !important;
            color: darken($warning, 20%) !important;
            &:hover,
            &:focus,
            &:active {
              background: lighten($warning, 30%) !important;
              color: darken($warning, 20%) !important;
            }
          }
        }
        &-graph {
          //background: purple;
          margin-left: 10px;
          flex: 1;
        }
      }
    }
  }
  &__ticket-table {
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .reporting__tab-based-dropdown {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        border: 1px solid $tint3;
        &__name {
          padding-left: 15px;
          padding-right: 15px;
          font-size: 0.88rem;
          color: $primary;
          background: $primary-light;
          height: 100%;
          display: flex;
          align-items: center;
        }
        &__button {
          border-radius: 0 !important;
          box-shadow: none !important;
          font-size: 0.88rem !important;
          color: $grey;
          background: transparent !important;
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
            align-items: center;
          }
          &:hover,
          &:focus,
          &:active {
            background: transparent !important;
          }
        }
      }
    }
  }
}
.report-additional-tooltips {
  background: $primary-light;
  color: $primary;
  cursor: default;
  border: none !important;
  margin-top: 10px;
  box-shadow: none !important;
  &:hover,
  &:focus,
  &:active {
    background: $primary-light !important;
    color: $primary !important;
    cursor: default !important;
    border: none !important;
    margin-top: 10px;
    box-shadow: none !important;
  }
}
.reporting-title-container {
  color: gray;
  font-size: 0.88rem;
  display: flex;
  align-items: center;
}
.reporting-div-tab {
  display: flex;
  align-items: center;
  &__elem {
    padding: 4px 20px;
    border: 1px solid $tint3;
    cursor: pointer;

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &:first-of-type {
      border-right: none;
      border-radius: 5px 0 0 5px;
    }
    &:hover {
      background: $primary-light;
    }
  }
  .active {
    background: $primary-light;
  }
}
.reporting-row-card__loader {
  display: flex;
  align-items: flex-end;
  min-height: 60px;
}
.hightlighted-bg {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.88rem;
  color: $primary;
  background: $primary-light;
  height: 100%;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  &-danger {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.88rem;
    background: #fae2e1;
    height: 100%;
    width: fit-content;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $danger;
    border-radius: 3px;
  }
}
.hightlighted-bg-blue {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.88rem;
  color: #046775;
  background: rgba(21, 168, 215, 0.21);
  height: 100%;
  width: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
}
