@import '../../assets/styles/colors';

.dashboard-navbar-horizontal {
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 2rem;

  &__right {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 40px;
    }
    & * {
      cursor: pointer;
    }
  }
}

.dashboard-navbar-vertical {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
  height: 100vh;
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28);

  .toggle-icon {
    position: absolute;
    right: -17px;
    top: 85px;
    background: $secondary-off-white;
    border-radius: 30px;
    box-shadow: none !important;
    border: 1px solid $secondary-off-white;
    transition: all 0.3s ease-in-out;
  }
  .toggle-icon:hover {
    background: $secondary-off-white !important;
    box-shadow: -2px -2px 5px $white, 2px 2px 5px $tint2 !important;
  }
  .toggle-icon:active {
    box-shadow: -1px -1px 3px $white, 1px 1px 3px $tint2 !important;
  }
  .logo-container {
    width: 100%;
    //background: $secondary-off-white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    transition: all 0.3s ease-in;
    &__holder {
      background: rgba(255, 255, 255, 0.15);
      border-radius: 100px;
      height: 50px;
      width: 50px;
      position: relative;
      img {
        position: absolute;
        top: 10px;
        left: 12px;
      }
    }
    &__info {
      text-align: left;
      margin-left: 10px;
      .title {
        font-family: $poppins;
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 600;
        color: $secondary-2;
        margin: -5px 0 0;
        width: 8.5rem;
      }
      .subtitle {
        font-size: 0.7rem;
        font-family: $poppins;
        color: $secondary-4;
        margin: -2px 0 0;
      }
    }
  }

  &.is-open {
    width: 16rem;
    .side-menu-item {
      transition: 0.25s all;
      padding-left: 20px;
      border-left: 5px solid $secondary-off-white;
      width: 90%;
      border-radius: 0 100px 100px 0;
      &:hover {
        transition: 0.25s all;
        background: lighten($secondary-off-white-2, 1%);
        border-left: 5px solid $secondary-4;
      }
    }
    .active {
      background: $primary-light;
      border-left: 5px solid $primary;
      &:hover {
        background: $primary-light;
        border-left: 5px solid $primary-dark;
      }
    }
    > .dashboard-navbar-vertical__right {
      margin-left: 0;
    }
  }

  &.dark {
    background-color: $secondary-dark;
  }
  &.light {
    background-color: $secondary-off-white;
  }
  &__left {
    display: flex;
    padding: 15px 10px 0px;
    align-items: center;
    flex-direction: row;
    img {
      max-width: 100px;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
    margin-top: 30px;
    width: 100%;
    margin-left: 30px;
    height: 100%;
    .side-menu-item {
      display: flex;
      align-items: center;
      //margin-bottom: 15px;
      text-decoration: none;
      color: $secondary-3;
      width: fit-content;
      padding: 10px 10px;
      p {
        font-size: 0.9rem;
        margin-left: 12px;
      }
    }
    .active {
      button {
        background: $secondary-3;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
    & *:not(p) {
      cursor: pointer;
    }
  }

  &__account {
    position: absolute;
    bottom: 15px;
    left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    p {
      margin-left: 5px;
    }
  }
}

.image-upload,
.file-upload {
  border: 1px dashed $secondary-4;
  border-radius: 4px;
  background: $white;
  cursor: pointer;

  &__field {
    padding: 15px;
    text-align: center;
    color: $secondary-4;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.color-picker-container {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  margin-top: 5px;
  position: relative;
  &__popover {
    position: absolute;
    z-index: 2;
  }
}

input.form-control {
  position: relative;
  padding: 4px 7px;
  height: 28px;
  cursor: text;
  font-size: 12px;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.pro-badge {
  position: relative;

  &:before {
    content: 'PRO';
    position: absolute;
    top: 0px;
    right: -10px;
    background: #ec4c47;
    color: $white;
    font-size: 9px;
    padding: 2px 3px;
    border-radius: 2px;
    line-height: 1;
  }
}

.trial-remaining {
  position: fixed;
  background: $secondary-off-white;
  width: 200px !important;
  height: 260px;
  top: 10px;
  right: -200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  z-index: 10;
  transition: 0.4s right linear;
  &__value {
    font-size: 3rem;
    font-weight: 800;
    height: 100%;
    font-family: $poppins;
    background: $primary-gradient;
    padding: 10px auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.is-shown {
    transition: 0.4s right linear;
    right: 0;
  }

  p {
    text-align: center;
    margin-bottom: 10px;
  }

  .pill-icon {
    position: absolute;
    top: 5px;
    left: -33px;
    z-index: 20;
  }
  .icon {
    border-radius: 4px 0 0 4px;
    position: absolute;
    background: $secondary-off-white;
    padding: 7px;
    top: 15px;
    left: -40px;
    height: 40px;
    width: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.alice-message-component {
  background-color: #fff;
  box-shadow: -20px 40px 100px rgba(88, 92, 98, 0.04),
    -15px 40px 100px rgba(66, 0, 255, 0.08);
  border-radius: 3px;
  padding: 15px;
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 10px;
    width: 60px;
  }

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #172b4d;
    padding-bottom: 10px;
  }

  &.text-success {
    background: #eafff4;
  }

  p {
    font-size: 12px;
    line-height: 20px;
    color: #172b4d;
  }
}

.alice-pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .rc-pagination-item {
    border: none !important;

    a {
      color: #172b4d !important;
    }
  }

  .rc-pagination-item:hover,
  .rc-pagination-item:focus {
    background-color: #253858 !important;
    a {
      color: #fff !important;
    }
  }

  .rc-pagination-item-active {
    background-color: #253858 !important;

    a {
      color: #fff !important;
    }
  }

  .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-next .rc-pagination-item-link {
    border: none !important;
  }

  .rc-pagination-next button:after,
  .rc-pagination-prev button:after {
    font-size: 20px;
    margin-top: -5px;
  }
}

.alice-trial-card {
  background: $white;
  padding: 20px;

  a {
    color: #0091ae;
  }
}
