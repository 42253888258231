@import '../../../../assets/styles/_typography.scss';
@import '../../../../assets/styles/_colors.scss';
ul {
  li {
    list-style: disc;
  }
}
.auth-container {
  height: 100vh;
  overflow: hidden;
  color: $white;
  display: flex;
  flex-direction: column;
}
.info-container {
  background: $primary-gradient-reverse;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  &__content {
    margin-left: 15%;
    > .title {
      color: $white;
      font-family: $poppins;
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: bold;
      width: 80%;
      letter-spacing: 2px;
    }
    > .bar {
      border: 3px solid $white;
    }
    > .subtitle {
      color: $white;
      width: 90%;
      margin-top: 20px;
      font-size: 0.9rem;
      font-family: $poppins;
      font-weight: 300;
    }
  }
}
.bar {
  width: 60px;
  margin-top: 10px;
}
.auth-button {
  background: transparent !important;
  color: $white !important;
  font-weight: bold !important;
  border: 2px solid $white !important;
  box-sizing: content-box !important;
  margin-top: 20px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease-in !important;
  box-shadow: none !important;
  &:hover,
  &:focus {
    background: $white !important;
    color: $primary-dark !important;
    box-shadow: $box-shadow-button-secondary !important;
  }
  &:active {
    box-shadow: $box-shadow-button-secondary !important;
  }
}

.signup-checkbox {
  margin: 10px 0;
  > span {
    color: $secondary-4;
    font-size: 0.8rem;
  }
  > input:checked + div {
    background-image: linear-gradient(
      to bottom,
      $primary,
      $primary-dark
    ) !important;
  }
}
.header-container {
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: $black;
  padding: 30px 40px 0 40px;
}

.logo-dark {
  width: 80%;
}

.signup-info {
  color: $secondary-4;
  font-size: 0.8rem;
  margin: 5px 0;
  a {
    text-decoration: none;
    color: $link-color;
  }
}

.form-container {
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  position: relative;
  &__content {
    min-width: 400px;
    padding-bottom: 50px;
    > .title {
      color: $secondary-2;
      font-family: $poppins;
      //text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem;
      width: 100% !important;
      font-weight: 500;
      //font-weight: bold;
      //letter-spacing: 2px;
    }
    .bar {
      border: 3px solid $primary;
      width: 10%;
      margin-left: 45%;
    }
    .tos {
      color: $secondary-4;
      font-size: 0.65rem;
      a {
        text-decoration: none;
        color: $primary;
        font-weight: bold;
      }
    }
    .additional-info {
      color: $secondary-4;
      font-size: 0.9rem;
      margin: 5px 0;
      a {
        text-decoration: none;
        color: $link-color;
      }
    }
    .reminder-info {
      color: $secondary-4;
      font-size: 0.7rem;
      a {
        text-decoration: none;
        color: $link-color;
      }
    }
    .footer-info {
      color: $secondary-4;
      font-size: 0.6rem;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      > .footer-link {
        text-align: center;
        a {
          color: $link-color;
        }
      }
    }
  }
}
.resend-verification {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.resend-timer-button {
  background: rgba(9, 30, 66, 0.04);
  color: $text-color !important;
  height: 32px !important;
  width: 70px;
  margin-left: 11px !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in;
}
.resend-link-button {
  background: $primary-2;
  color: $white !important;
  height: 32px !important;
  width: 70px;
  margin-left: 11px !important;
  box-shadow: none !important;
  transition: all 0.2s ease-in;
  &:hover {
    background: rgba(120, 9, 52, 0.36) !important;
  }
}
.h-75px {
  height: 75px;
}
.w-300 {
  max-width: 300px;
  margin: auto !important;
}
.w-400 {
  max-width: 400px;
  margin: auto !important;
}
.pt-20 {
  padding-top: 20px;
}
.t-center {
  text-align: center;
}
.auth-login-button {
  background: $secondary-rd !important;
  color: $white !important;
  justify-content: center;
  transition: all 0.1s ease-in;
  width: 100%;
  &:hover {
    background: $secondary-rd-hover !important;
  }
  &:disabled {
    div {
      svg {
        circle {
          stroke: $white !important;
        }
      }
    }
  }
}

.top-left-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
}
.top-bottom-bg {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 150px;
}
.padlockIcon {
  width: 128px;
  height: 128px;
}

.passwordScore {
  display: none;
}
.timeNotification {
  font-size: 12px;
  font-weight: normal;
  color: #6b778c;
  margin-top: 5px;
  text-align: center;
}
.timer {
  color: red;
}
//responsive

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .info-container {
    display: none;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .info-container {
    display: none;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .info-container {
    display: none;
  }
}
