@import '../../../../assets/styles/_typography.scss';
@import '../../../../assets/styles/_colors.scss';

ul {
  li {
    list-style: disc;
  }
}

.welcome-container {
  height: 100vh;
  overflow: hidden;
  background: $white;
  color: $secondary-dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-img {
  max-height: 40%;
}

.title {
  margin-top: 25px;
  font-family: $poppins;
  font-size: 1.3rem;
  font-weight: 400;
  width: 80%;
}

.subtitle {
  margin-top: 10px;
  font-size: 0.9rem;
  font-family: $poppins;
  font-weight: 300;
}

.next-button {
  background: $secondary-rd !important;
  color: $white !important;
  justify-content: center;
  transition: all 0.1s ease-in;
  &:hover {
    background: $secondary-rd-hover !important;
  }
  &:disabled {
    div {
      svg {
        circle {
          stroke: $white !important;
        }
      }
    }
  }
}

//responsive

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .info-container {
    display: none;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .info-container {
    display: flex;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .info-container {
    display: none;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .info-container {
    display: none;
  }
}
