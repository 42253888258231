@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';

.marketplace-text {
  &-info {
    color: $secondary-rd;
    font-size: 12px;
  }
  &-success {
    color: $success;
    font-size: 12px;
  }
  &-default {
    color: $secondary-dark;
    font-size: 12px;
  }
}
.default-bg {
  background: $default-color;
  border-radius: 3px;
}

.marketplace {
  &__container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  &-card {
    background: $white;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2),
      0px 0px 1px rgba(9, 30, 66, 0.31);
    max-width: 250px;
    margin: 10px;
    padding: 20px;
    border-radius: 3px;
    &__title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 5px;
      font-family: $poppins;
    }
    &__subtitle {
      font-size: 0.7rem;
      font-weight: 400;
      font-family: $poppins;
      margin-bottom: 5px;
      color: $description-color;
    }
  }
}
.woocommerce-modal {
  padding: 15px;
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  &__subtitle {
    font-size: 0.75rem;
    font-weight: 400;
    color: $description-color;
  }
  &__content {
    margin-top: 20px;
    &-title {
      font-size: 1.2rem;
      font-weight: 500;
    }
    &-subtitle {
      font-size: 0.7rem;
      font-weight: 400;
      color: $description-color;
      width: 80%;
    }
    &__success-title {
      font-size: 1.25rem;
      color: $primary;
      font-family: $poppins;
      font-weight: 600;
      text-align: center;
    }
    &__success-subtitle {
      font-size: 1.25rem;
      color: $secondary-3;
      font-family: $poppins;
      text-align: center;
    }
  }
}
