//variables;
$material-roboto: 'Roboto', sans-serif;
$material-primary: #eebb4d;
$material-secondary: #8d99ae;
$material-secondarydark: #484b69;
$material-disabled: #8d99ae;

//code
.material-outline-input {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: $material-roboto;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;

  > {
    input,
    textarea {
      box-sizing: border-box;
      margin: 0;
      border: solid 1px;

      /* Safari */
      border-color: $material-secondary;
      border-top-color: transparent;
      border-radius: 4px;
      padding: 10px 13px 10px;
      width: 100%;
      height: inherit;
      color: $material-secondarydark;
      background-color: transparent;
      box-shadow: none;

      /* Firefox */
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      caret-color: $material-secondary;
      transition: border 0.2s, box-shadow 0.2s;
    }

    .visible-eye {
      color: $material-secondary;
      position: absolute;
      top: 40%;
      right: 5%;
      cursor: pointer;
    }

    input + span,
    textarea + span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      border-color: $material-primary;
      width: 100%;
      max-height: 100%;
      color: $material-secondary;
      font-size: 75%;
      line-height: 15px;
      cursor: text;
      transition: color 0.2s, font-size 0.2s, line-height 0.2s;
    }

    input + span {
      &::before,
      &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        margin-top: 6px;
        border-top: solid 1px;
        border-top-color: $material-secondary;
        min-width: 10px;
        height: 8px;
        pointer-events: none;
        box-shadow: inset 0 1px transparent;
        transition: border-color 0.2s, box-shadow 0.2s;
      }
    }

    textarea + span {
      &::before,
      &::after {
        content: '';
        display: block;
        box-sizing: border-box;
        margin-top: 6px;
        border-top: solid 1px;
        border-top-color: $material-secondary;
        min-width: 10px;
        height: 8px;
        pointer-events: none;
        box-shadow: inset 0 1px transparent;
        transition: border-color 0.2s, box-shadow 0.2s;
      }
    }

    input + span::before,
    textarea + span::before {
      margin-right: 4px;
      border-left: solid 1px transparent;
      border-radius: 4px 0;
    }

    input + span::after,
    textarea + span::after {
      flex-grow: 1;
      margin-left: 4px;
      border-right: solid 1px transparent;
      border-radius: 0 4px;
    }
  }

  &:hover > {
    input,
    textarea {
      border-color: $material-secondary;
      border-top-color: transparent;
    }

    input + span::before,
    textarea + span::before,
    input + span::after,
    textarea + span::after {
      border-top-color: $material-secondary;
    }

    input:not(:focus):placeholder-shown,
    textarea:not(:focus):placeholder-shown {
      border-color: $material-secondary;
    }
  }

  > {
    input:not(:focus):placeholder-shown,
    textarea:not(:focus):placeholder-shown {
      border-top-color: $material-secondary;
    }

    input:not(:focus):placeholder-shown + span,
    textarea:not(:focus):placeholder-shown + span {
      font-size: inherit;
      line-height: 60px;
      color: $material-secondary;
    }

    input:not(:focus):placeholder-shown + span::before,
    textarea:not(:focus):placeholder-shown + span::before,
    input:not(:focus):placeholder-shown + span::after,
    textarea:not(:focus):placeholder-shown + span::after {
      border-top-color: transparent;
    }

    input:focus,
    textarea:focus {
      border-color: $material-primary;
      border-top-color: transparent;
      box-shadow: inset 1px 0 $material-primary, inset -1px 0 $material-primary,
        inset 0 -1px $material-primary;
      outline: none;
    }

    input:focus + span,
    textarea:focus + span {
      color: $material-primary;
    }

    input:focus + span {
      &::before,
      &::after {
        border-top-color: $material-primary !important;
        box-shadow: inset 0 1px $material-primary;
      }
    }

    textarea:focus + span {
      &::before,
      &::after {
        border-top-color: $material-primary !important;
        box-shadow: inset 0 1px $material-primary;
      }
    }

    input:disabled,
    textarea:disabled {
      border-color: $material-disabled !important;
      border-top-color: transparent !important;
      color: $material-disabled;
      pointer-events: none;

      + span {
        border-color: $material-disabled !important;
        border-top-color: transparent !important;
        color: $material-disabled;
        pointer-events: none;
      }
    }

    input:disabled + span {
      &::before,
      &::after {
        border-top-color: $material-disabled !important;
      }
    }

    textarea:disabled + span {
      &::before,
      &::after {
        border-top-color: $material-disabled !important;
      }
    }

    input:disabled:placeholder-shown,
    textarea:disabled:placeholder-shown {
      border-top-color: $material-disabled !important;

      + span {
        border-top-color: $material-disabled !important;
      }
    }

    input:disabled:placeholder-shown + span {
      &::before,
      &::after {
        border-top-color: transparent !important;
      }
    }

    textarea:disabled:placeholder-shown + span {
      &::before,
      &::after {
        border-top-color: transparent !important;
      }
    }
  }
}

/* Input, Textarea */

/* Span */

/* Corners */

/* Hover */

/* Placeholder-shown */

/* Focus */

/* Disabled */

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .material-outline-input > {
      input,
      textarea {
        transition-duration: 0.1s;

        + span {
          transition-duration: 0.1s;
        }
      }

      input + span {
        &::before,
        &::after {
          transition-duration: 0.1s;
        }
      }

      textarea + span {
        &::before,
        &::after {
          transition-duration: 0.1s;
        }
      }
    }
  }
}
