@import '../colors';

.redirect-condition__card {
  position: relative;
  background: $tint;
  margin: 10px 0;
  border: 1px solid darken($tint, 5%);
  padding: 10px 10px 20px;
  border-radius: 5px;
  cursor: default;
  &-body {
    display: flex;
    margin-top: 5px;
    justify-content: space-evenly;
    div {
      width: 100%;
      margin: 0 5px;
      button {
        width: 100%;
      }
      .flex-1 {
        flex: 1;
      }
      .flex-2 {
        flex: 2;
      }
    }
  }
  .delete-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.redirect-segment {
  div {
    input:checked + label {
      background: $primary;
      box-shadow: inset 0 0 0 1px rgba(21, 60, 47, 0.49),
        inset 0 -1px 1px 0 rgba(22, 156, 113, 0.42);
      color: $white;
    }
  }
}
