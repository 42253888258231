.variable__input {
  position: relative;
  &:before {
    font-size: 14px;
    color: #8e9aae;
    position: absolute;
    top: 8px;
    left: 10px;
    content: '{{';
  }
  &:after {
    font-size: 14px;
    color: #8e9aae;
    position: absolute;
    top: 8px;
    right: 10px;
    content: '}}';
  }
  input {
    padding: 0 20px;
    width: 100%;
    text-align: center;
  }
}
