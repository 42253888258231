@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/typography';
.channel-settings {
  &__container {
    background: white;
    border: 0.5px solid $border-color;
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;
    overflow: auto;
  }
  &__header {
    font-size: 1.75rem;
  }
  &__description {
    font-family: $poppins;
  }
  &__card {
    border: 0.5px solid $border-color;
    margin-bottom: 30px;
    &-channel-create {
      padding: 0 30px;
      &-banner {
        margin: 20px auto 20px;
        text-align: center;
        width: 80px;
        display: flex;
      }
      &-title {
        font-size: 28px;
        font-weight: 500;
        text-align: center;
        margin-top: 20px;
      }
      &-description {
        font-size: 14px;
        font-weight: 400;
        color: $light-grey;
        text-align: center;
        margin-bottom: 30px;
      }
    }
    &-disable-warning {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 40px;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      background: $white;
      border-bottom: 0.5px solid $secondary-off-white;
      padding: 10px;
      align-items: center;
      &-title {
        font-size: 18px;
        font-family: $poppins;
        margin-left: 14px;
      }
    }
    &-holder {
      &-elem {
        padding: 10px 15px;
        display: flex;
        border-bottom: 0.5px solid $secondary-off-white;
        color: $light-grey;
        &:last-child {
          border-bottom: none;
        }
        &-item {
          width: 25%;
          font-size: 0.88rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-right: 20px;
          div {
            box-shadow: none !important;
          }
          &:nth-child(4) {
            width: 12.5%;
          }
          &:nth-child(5) {
            width: 12.5%;
          }
        }
      }
    }
  }
}
.icon-button__no-box-shadow {
  box-shadow: none !important;
}
.facebook-button-connect {
  margin: 0 auto 40px;
  display: flex;
}
.channel-connect-fb__modal-container {
  display: flex;
  &-image {
    width: 80px;
  }
  &-page-list-item {
    display: flex;
    justify-content: space-between;
    background: rgba(98, 185, 255, 0.15);
    padding: 10px 30px;
    margin: 5px;
    border-radius: 5px;
    font-weight: 400;
  }
  &-content {
    margin-left: 10px;
    &-title {
      font-size: 28px;
      font-weight: 500;
      text-align: left;
      margin-top: 20px;
    }
    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $light-grey;
      text-align: left;
      margin-bottom: 30px;
    }
  }
}
.alice-connect-style_2 {
  padding: 24px 30px 24px !important;
  transition: all 0.2s ease-in;
  div {
    svg {
      circle {
        stroke: #fff;
      }
    }
  }
}
.channel-edit-modal {
  &__container {
    padding: 30px 30px;
    &-platform {
      margin-top: 30px;
      &__add-more-button {
        color: $facebook !important;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__url {
    border-radius: 0 !important;
    box-shadow: inset 0 0 0 1px #3378b94d, inset 0 1px 2px #4b7faf24 !important;
    height: auto !important;
    padding: 7px 10px !important;
    strong {
      font-size: 14px !important;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &-content {
      &-title {
        font-size: 24px;
        line-height: 1;
        padding-bottom: 10px;
      }
      &-subtitle {
        font-size: 0.88rem;
        color: $light-grey;
      }
    }
    &-actions {
      display: flex;
    }
  }
}
.edit-modal-small-header {
  &__title {
    font-family: $poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__subtitle {
    font-family: $poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
  }
}
.link-style-dropdown {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-size: 14px !important;
  color: #425a70 !important;
  font-weight: 600 !important;
  margin-top: -8px !important;
  img {
    width: 20px;
  }
}
.slider-container {
  width: 80% !important;
  margin-bottom: 30px;
  cursor: pointer;
  &__marker {
    background: $primary;
    p {
      margin-top: 10px;
      font-size: 0.9rem;
      color: $secondary-2;
    }
  }
}
.primary-color {
  margin-left: 20%;
  margin-top: -32px;
  &-picker {
    box-shadow: 0px 1px 15px rgba(35, 23, 5, 0.26) !important;
    width: 80% !important;
    margin-top: 20px !important;
    border-radius: 15px !important;
  }
}
