@import '../colors';

$width: 220px;

.gallery-block {
  max-width: 635px;

  &__droppable {
    display: flex;
    overflow: auto;
  }

  &__draggable {
    flex: 1;
  }

  &__item {
    padding: 15px;
    margin-right: 10px;
    background: $secondary-demi-white;
    border: 1px solid $secondary-off-white;
    position: relative;
    min-width: $width;
    margin-top: 10px;

    input {
      width: 100%;
      margin-top: 10px;
    }

    &-add {
      background: $secondary-demi-white;
      border: 1px solid $secondary-off-white;
      flex: 1;
      min-width: $width;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 10px 0 0;
      svg {
        fill: $secondary-4 !important;
      }
    }

    &-delete {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -10px;
      right: -5px;
      cursor: pointer;
      transform: scale(0.9);
      transition: all 0.3s ease-in-out;
      svg {
        fill: #ff6969 !important;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
    &:hover {
      .gallery-block__item-delete {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .button-group-create-container {
    display: flex;
    width: 100%;
    .button-group__child {
      flex: 1;
    }
  }
}
.aspect-ratio__container {
  p {
    margin-top: 10px;
    margin-bottom: 0;
  }
  div {
    input:checked + div {
      background-image: $primary-gradient;
    }
  }
}
