@import '../colors';

.block-container {
  position: relative;
  background: $white;
  padding: 15px;
  border: 1px solid darken($secondary-off-white, 1%);
  //width: 100%;
  max-width: 100%;
  margin-bottom: 15px;

  &__dnd {
    position: absolute;
    left: -25px;
    background: $white;
    border: 1px solid $secondary-off-white;
    border-right: none;
    padding: 5px 6px;
    border-radius: 5px 0 0 5px;
    top: 10px;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    cursor: grab;
  }

  &:hover,
  &:active {
    .block-container__dnd {
      visibility: visible;
      opacity: 1;
    }
  }

  &__flex {
    display: flex;
    img {
      margin-right: 10px;
      margin-top: -15px;
      width: 30px;
    }
  }
  h5 {
    font-size: 12px;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
    color: $secondary-4;
    margin-bottom: 15px;
  }

  textarea {
    resize: vertical;
  }

  &__save_button {
    position: absolute !important;
    background: $primary-gradient !important;
    color: $white !important;
    box-shadow: none;
    top: 8px !important;
    right: 40px !important;
    border: none;
    cursor: pointer;
    &:hover {
      background: $primary-gradient-reverse !important;
      box-shadow: $box-shadow-button2 !important;
    }
    &:disabled {
      background: $tint !important;
      color: $secondary-4 !important;
      box-shadow: none !important;
      cursor: not-allowed !important;
    }
  }

  .menu-icon {
    position: absolute;
    top: 3px;
    right: 3px;
  }
}
